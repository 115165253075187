import { Box } from '@chakra-ui/core'
import React from 'react'

import {
  SliderProps,
  SliderInternalState,
} from './types'
import { getInitialState, getIfSlideIsVisbile } from './functions'

type SliderItemsProps = {
  props: SliderProps
  state: SliderInternalState
  clones: any[]
  notEnoughChildren: boolean
}

const SliderItems: React.FC<SliderItemsProps> = ({
  props,
  state,
  clones,
}) => {
  const { itemWidth } = state
  const {
    children,
    infinite,
    gap,
  } = props

  const {
    flexBasis,
    shouldRenderOnSSR,
    domFullyLoaded,
    shouldRenderAtAll
  } = getInitialState(state, props)

  if (!shouldRenderAtAll) {
    return null
  }

  return (
    <>
      {(infinite ? clones : React.Children.toArray(children)).map(
        (child, slideIndex) => {
          return (
            <Box
              as="li"
              key={slideIndex}
              data-index={slideIndex}
              aria-hidden={getIfSlideIsVisbile(slideIndex, state) ? 'false' : 'true'}
              flex={shouldRenderOnSSR ? `1 0 ${flexBasis}%` : 'auto'}
              px={[0, gap || 3]}
              position="relative"
              width={ domFullyLoaded ? itemWidth : 'auto'}
            >
              {child}
            </Box>
          );
        }
      )}
    </>
  )
}

export default SliderItems
